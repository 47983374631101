import type { ReactiveController, ReactiveControllerHost } from 'lit';
import type { OidcManager, ClientParams, Client, MessageEvent } from '@rhitm-incubator/oidc-manager';

/**
 * Controls a negative offset for when the
 * JWT should automatically refresh in relation
 * to the expires_in value.
 */
const TOKEN_REFRESH_OFFSET = 25;

declare global {
  interface Window {
    oidcManager: OidcManager;
    drupalSettings?: {
      rh_oidc_manager?: {
        oidc_settings?: ClientParams;
      };
    };
  }
}

/**
 * A promise that indicates if the oidcManager
 * singleton is available.
 */
export const oidcManagerResolved: Promise<OidcManager> = new Promise(res => {
  if (window.oidcManager) { return res(window.oidcManager); }
  window.addEventListener('oidc-manager-registered', () => {
    res(window.oidcManager);
  }, { once: true });
});

/**
 * An EventTarget based class that connects to the
 * OIDC Manager singleton. The client options are
 * inherited from the drupalSettings global object.
 */
class MyRHClient extends EventTarget {
  private _client?: Client;
  private messageHandler = this.messageHandlerCallback.bind(this);
  private _state: 'default' | 'initializing' | 'authenticated' | 'unauthenticated' | 'error' = 'default';
  private refreshTokenTimeout?: ReturnType<typeof setTimeout>;
  private _options?: ClientParams;

  get state() {
    return this._state;
  }

  set state(state: typeof this._state) {
    this._state = state;
    this.dispatchEvent(new Event('update'));
  }

  get client() {
    return this._client;
  }

  set client(client: Client | undefined) {
    this._client = client;
    this.dispatchEvent(new Event('update'));
  }

  public async createClient(options?: ClientParams) {
    // only create a client if it does not already exists
    if (this.state !== 'default') {
      return this.client;
    }
    // set the state to initializing
    this.state = 'initializing';
    // wait for the global singleton to be available
    await oidcManagerResolved;
    // check for the existence of the client options
    if (!window?.drupalSettings?.rh_oidc_manager?.oidc_settings) {
      console.error('Client options not found in drupalSettings');
      this.state = 'error';
      return;
    }

    // Merge options and settings
    this._options = {
      ...window.drupalSettings.rh_oidc_manager.oidc_settings,
      ...options
    };

    // subscribe to the OIDC Manager message bus
    window.oidcManager.addEventListener('message', this.messageHandler);
    // create the client for the OIDC Manager
    this.client = window.oidcManager.createClient(this._options);
    // if redhat.com doesn't see the 'rhSsoSessionCookie' it
    // won't automatically attempt to authenticate the user
    // so we have to forcibly do it.
    if (this._options.force_redirect === true) {
      // TODO: use client.login() instead. Requires OIDC Manager version ^0.4 on redhat.com
      this.client.manager?.signinRedirect({
        redirect_uri: window.location.href
      });
    } else {
      // TODO: we need an alternative to calling _signinSilent
      // in OIDC Manager. To obvoid this, we would need to either
      // specifiy in client.login() that we want to override the current
      // force_redirect setting for the client, or add options to client.login()
      // that would allow use to override the current force_redirect setting.
      // @ts-ignore
      this.client._signinSilent?.();
    }

    return this.client;
  }

  private messageHandlerCallback(e: Event) {
    const event = e as MessageEvent;
    if (event.detail.requester !== this.client) { return; }
    if (event.detail.type === 'client_init') {
      this.state = 'initializing';
      return;
    } else if (event.detail.type === 'error') {
      if (event.detail.error) {
        const error = event.detail.error as { message: string };
        // OIDC Manager does another silent login attempt if the token is expired
        // so we need to wait for another event
        if (error.message === 'Session not active' || error.message === 'Token is not active') {
          // just let this message fall through and wait...
          return;
        }
        if (error.message === 'login_required' || error.message === 'interaction_required') {
          // login
          if (this._options?.force_redirect) {
            this.client?.login();
          }
          this.state = 'unauthenticated';
          return;
        }
        this.state = 'error';
        return;
      }
    } else {
      if (event.detail.type === 'login') {
        // register a refresh timeout for the client
        this.loginCallback();
      }
      this.state = this.client.user ? 'authenticated' : 'unauthenticated';
    }
  }

  /**
   * Registers a timeout to refresh the user token
   * before it expires
   */
  private loginCallback() {
    if (!this.client?.user) { return; }

    // JWT lifetime represented as seconds
    const expiresIn = this.client.user?.expires_in;
    if (expiresIn) {
      // resolve timeout 25 seconds before the token expires
      const timeout = (expiresIn - TOKEN_REFRESH_OFFSET) * 1000;
      this.refreshTokenTimeout = setTimeout(this.refreshToken.bind(this), timeout);
    }
  }

  /**
   * Refresh the JWT token using OIDC Manager
   * standard login method.
   */
  private refreshToken() {
    // if we are already logged out
    // do not refresh
    if (!this.client?.user) { return; }

    this.client?.login();
  }
}

/**
 * A singleton instance of the MyRHClient class.
 */
export const myRHClient = new MyRHClient();

/**
 * A Lit controller that reflects the state of the myRHClient
 * singleton to the host element.
 */
export class OidcManagerController implements ReactiveController {
  host: ReactiveControllerHost;
  /** @deprecated */
  private options: ClientParams;
  public client?: Client;
  public state: MyRHClient['state'] = myRHClient.state;
  private messageHandler = this.messageHandlerCallback.bind(this);

  /**
   * @param {ReactiveController} host - The host element that the controller is attached to.
   * @deprecated @param {ClientParams} options - The options to pass to the oidc-manager client.
   */
  constructor(host: ReactiveControllerHost, options: ClientParams) {
    (this.host = host).addController(this);
    this.options = options;
  }

  hostConnected(): void {
    this.createClient();
    myRHClient.addEventListener('update', this.messageHandler);
  }

  hostDisconnected(): void {
    myRHClient.removeEventListener('update', this.messageHandler);
  }

  private async createClient() {
    this.client = await myRHClient.createClient(this.options);
  }

  private messageHandlerCallback() {
    this.client = myRHClient.client;
    this.state = myRHClient.state;
    this.host.requestUpdate();
  }
}
